<template>
  <div>
    <v-form
      ref="user_form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col cols="12">
          <base-card
            color="indigo"
            icon="mdi-vuetify"
            inline
            class="px-5 py-3"
            :avatar="avatarPath"
            title="Профиль"
          >
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="user.surname" label="Фамилия" :rules="[v => !!v || 'Обязательное поле']"/>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="user.name" label="Имя" :rules="[v => !!v || 'Обязательное поле']"/>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="user.patronymic" label="Отчество" :rules="[v => !!v || 'Обязательное поле']"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="user.position" label="Должность" :rules="[v => !!v || 'Обязательное поле']"/>
                <v-treeview
                  v-model="user.department"
                  :items="treeList"
                  dense
                  selectable
                  activatable
                  hoverable
                  selection-type="independent"
                  open-all
                  open-on-click
                >
                </v-treeview>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model.trim="user.email" label="E-Mail" />
                <date-picker v-model="user.birthday"/>
                <v-file-input
                  v-model="avatar"
                  label="Аватар"
                  chips
                />
                <v-select
                  v-model="user.roles"
                  :items="treeRoles"
                  item-value="id"
                  item-text="description"
                  multiple
                  small-chips
                  deletable-chips
                  label="Роли"
                  item-disabled="locked"
                  :rules="[v => (v && v.length !== 0) || 'Обязательное поле']"
                />
                <v-select
                  v-model="user.active"
                  :items="status"
                  label="Статус"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-masked-input
                  v-for="i in mobile_count"
                  :key="i"
                  v-model="user.mobile[i-1]"
                  :label="`Мобильный #${i}`"
                  :rules="[v => !!v || 'Обязательное поле']"
                  :mask="'+7 (###) ###-####'"
                >
                  <template v-slot:prepend>{{ `${i}.` }}</template>
                  <template v-slot:append-outer>
                    <v-btn icon color="primary" @click="add_mobile">
                      <v-icon>mdi-plus-thick</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="remove_mobile(i-1)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </template>
                </v-masked-input>
                <v-masked-input v-model="user.phone" label="Телефон" :mask="'+7 (###) ###-####'" />
                <v-combobox v-model="user.phone_inner" small-chips multiple label="Внутренний номер" />
              </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row>
              <v-col cols="12" class="d-flex justify-end">
                <v-btn small color="primary" :disabled="!valid" @click="saveData">Сохранить</v-btn>
              </v-col>
            </v-row>
          </base-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import VMaskedInput from '../VMaskedInput'
export default {
  name: 'UserForm',
  components: { VMaskedInput },
  props: {
    user: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data: () => ({
    valid: true,
    avatar: null,
    status: [
      { value: 0, text: 'Активен' },
      { value: 1, text: 'Не активен' },
      { value: 2, text: 'Уволен' }
    ]
  }),
  created () {
  },
  mounted () {
  },
  computed: {
    avatarPath: function () {
      return (this.user.avatar === null || this.user.avatar === undefined) ? '/static/default_avatar.png' : `/api/v1/uploads/avatar/${this.user.avatar}`
    },
    treeList: function () {
      return this.$store.state.bpm.department
    },
    treeRoles: function () {
      return this.$store.state.user.rolesAvailable
    },
    mobile_count: function () {
      return (this.user.mobile && this.user.mobile.length > 1) ? this.user.mobile.length : 1
    },
    phone: {
      get: function () {
        return this.user.phone
      },
      set: function (value) {
        console.log('Computed set', value)
        this.user.phone = value
      }
    }
  },
  methods: {
    saveData () {
      this.$emit('onUserSave', { avatar: this.avatar, form: this.$refs.user_form })
      this.avatar = null
    },
    uploadFile () {
      const id = this.user.id
      const rawData = JSON.stringify(this.user)
      const formData = new FormData()
      formData.append('avatar', this.avatar)
      formData.append('data', rawData)
      this.$store.dispatch('user/POST_UPLOAD_AVATAR', { id: id, formData: formData })
        .then(response => {
          this.flashMessage.success({ title: 'Сохранение', message: 'Аватар загружен.' })
          this.avatar = null
          this.user.avatar = response.avatar
        })
        .catch(error => {
          const message = error.response.data.message || ''
          this.flashMessage.error({ title: 'Сохранение', message: `Ошибка загрузки аватара. ${message}` })
        })
    },
    add_mobile () {
      this.user.mobile.push('')
      // this.result_count++
    },
    remove_mobile (val) {
      if (this.mobile_count > 1) {
        this.user.mobile.splice(val, 1)
        // this.result_count--
      }
    }
  }
}
</script>

<style scoped>

</style>
