<template>
  <v-container fluid>
    <user-form :user="user" @onUserSave="saveUser" />
  </v-container>
</template>

<script>
import UserForm from '../../components/form/UserForm'
export default {
  name: 'Edit',
  components: { UserForm },
  data: () => ({
    user_id: null,
    user: {
      mobile: []
    }
  }),
  created () {
    this.$eventHub.$on('back', this.goBack)
  },
  destroyed () {
    this.$eventHub.$off('back', this.goBack)
  },
  mounted () {
    this.user_id = this.$route.params.id
    this.getUser()
  },
  computed: {
  },
  methods: {
    getUser () {
      this.$store.dispatch('user/GET_ROLES_TREE')
      this.$store.dispatch('user/GET_USER', this.user_id)
        .then(response => {
          if (response.user.mobile === null) {
            response.user.mobile = ['']
          }
          this.user = response.user
        })
    },
    saveUser (value) {
      if (!value.form.validate()) {
        return
      }
      const id = this.user_id
      const payload = {}
      Object.assign(payload, this.user)
      payload.phone_inner = []
      if (this.user.phone_inner !== null) {
        this.user.phone_inner.forEach(function (item, index, arr) {
          payload.phone_inner.push(parseInt(item))
        })
      }
      const rawData = JSON.stringify(payload)
      const formData = new FormData()
      formData.append('avatar', value.avatar)
      formData.append('data', rawData)
      this.$store.dispatch('user/PUT_USER', { id: id, payload: formData }).then(response => {
        this.user = response.user
        this.flashMessage.success({ title: 'Сохранение', message: 'Данные пользователя успешно сохранены.' })
      }).catch(error => {
        const message = error.response.data.error || ''
        const title = error.response.data.title
        this.flashMessage.error({ title, message })
      })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
