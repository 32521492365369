<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-card
          color="indigo"
          icon="mdi-vuetify"
          inline
          class="px-5 py-3"
          :avatar="avatarPath"
          :title="title"
        >
          <v-row>
            <v-col cols="12" lg="10">
              <span v-if="$auth.check('hr')">
                <v-btn color="primary" outlined small :to="`/users/${user_id}/edit`">Изменить</v-btn>
              </span>
            </v-col>
          </v-row>
<!--          <v-row>-->
<!--            <v-col cols="12" md="4">-->
<!--              <v-text-field v-model="user.surname" label="Фамилия" readonly/>-->
<!--            </v-col>-->
<!--            <v-col cols="12" md="4">-->
<!--              <v-text-field v-model="user.name" label="Имя" readonly/>-->
<!--            </v-col>-->
<!--            <v-col cols="12" md="4">-->
<!--              <v-text-field v-model="user.patronymic" label="Отчество" readonly/>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--          <v-row>-->
<!--            <v-col cols="12" md="4">-->
<!--              <v-text-field v-model="user.position" label="Должность" readonly />-->
<!--            </v-col>-->
<!--          </v-row>-->
          <v-row>
            <v-col cols="12" md="4">
              <p><span class="font-weight-bold">День рождения:</span><br/> {{ $moment(user.birthday).format('LL') }}</p>
            </v-col>
            <v-col cols="12" md="4">
              <p> <span class="font-weight-bold">E-Mail:</span><br/>
                <a :href="`mailto:${user.email}`">{{ user.email }}</a>
              </p>
              <p> <span class="font-weight-bold">Telegram:</span><br/> {{ user.telegram || 'не указано' }} </p>
            </v-col>
            <v-col cols="12" md="4">
              <p class="d-flex flex-column">
                <span class="font-weight-bold">Мобильный номер:</span>
                <span
                  v-for="(num, idx) in user.mobile"
                  :key="idx"
                ><span class="font-weight-bold">{{ idx+1 }}.</span> {{ num | VMask('+7 (###) ###-####') }}</span>
              </p>
              <v-divider></v-divider>
              <p class="d-flex flex-column"> <span class="font-weight-bold">Телефон:</span>{{ user.phone }}
                <span class="font-weight-bold">Внутренний номер:</span>
                <span
                  v-for="(num, idx) in user.phone_inner"
                  :key="idx"
                ><span class="font-weight-bold">{{ idx+1 }}.</span> {{ num }}</span>
              </p>
            </v-col>
          </v-row>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Profile',
  data: () => ({
    user: {
      active: true,
      avatar: null,
      department: [],
      roles: [],
      email: null,
      mobile: null,
      name: null,
      patronymic: null,
      phone: null,
      phone_inner: [],
      position: null,
      surname: null,
      telegram: '',
      birthday: ''
    },
    user_id: null,
    avatar: null
  }),
  created () {
  },
  mounted () {
    this.user_id = this.$route.params.id
    this.getData()
  },
  computed: {
    avatarPath: function () {
      return (this.user.avatar === null) ? '/static/default_avatar.png' : `/api/v1/uploads/avatar/${this.user.avatar}`
    },
    title: function () {
      return `${this.user.surname} ${this.user.name} ${this.user.patronymic}<br/><span class="text-subtitle-1">${this.user.position}</span>`
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('user/GET_USER', this.user_id)
        .then(response => {
          this.user = response.user
        })
    }
  }
}
</script>

<style scoped>

</style>
