<template>
  <v-container fluid>
    <user-form :user="user" @onUserSave="saveUser" />
  </v-container>
</template>

<script>
import UserForm from '../../components/form/UserForm'
export default {
  name: 'Edit',
  components: { UserForm },
  data: () => ({
    user_id: null,
    user: {
      mobile: [],
      department: []
    },
    avatar: null
  }),
  created () {
  },
  mounted () {
    this.getData()
  },
  computed: {
    avatarPath: function () {
      return (this.user.avatar === null || this.user.avatar === undefined) ? '/static/default_avatar.png' : `/api/v1/uploads/avatar/${this.user.avatar}`
    },
    treeList: function () {
      return this.$store.state.bpm.department
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('user/GET_ROLES_TREE')
    },
    saveUser (value) {
      if (!value.form.validate()) {
        return
      }
      const payload = {}
      Object.assign(payload, this.user)
      payload.phone_inner = []
      if (this.user.phone_inner !== undefined && this.user.phone_inner !== null) {
        this.user.phone_inner.forEach(function (item, index, arr) {
          payload.phone_inner.push(parseInt(item))
        })
      }
      const rawData = JSON.stringify(payload)
      const formData = new FormData()
      formData.append('avatar', value.avatar)
      formData.append('data', rawData)
      this.$store.dispatch('user/POST_USER', formData).then(response => {
        this.flashMessage.success({ title: 'Сохранение', message: 'Данные пользователя успешно сохранены.' })
        this.$router.push({ path: `/users/${response.user.id}/view` })
      }).catch(error => {
        const message = error.response.data.error || ''
        const title = error.response.data.title
        this.flashMessage.error({ title, message })
      })
    },
    uploadFile () {
      const id = this.user_id
      const formData = new FormData()
      formData.append('avatar', this.avatar)
      this.$store.dispatch('user/POST_UPLOAD_AVATAR', { id: id, formData: formData })
        .then(response => {
          this.flashMessage.success({ title: 'Сохранение', message: 'Аватар загружен.' })
          this.avatar = []
          this.user.avatar = response.avatar
        })
        .catch(error => {
          const message = error.response.data.message || ''
          this.flashMessage.error({ title: 'Сохранение', message: `Ошибка загрузки аватара. ${message}` })
        })
    }
  }
}
</script>

<style scoped>

</style>
