<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="8">
        <base-card
          color="indigo"
          icon="mdi-vuetify"
          inline
          class="px-5 py-3"
          :avatar="avatarPath"
          :title="title"
        >
          <v-form
            ref="user_form"
            v-model="valid"
            lazy-validation
          >
<!--          <v-row>-->
<!--            <v-col cols="12" md="4">-->
<!--              <v-text-field v-model="user.surname" label="Фамилия" readonly/>-->
<!--            </v-col>-->
<!--            <v-col cols="12" md="4">-->
<!--              <v-text-field v-model="user.name" label="Имя" readonly/>-->
<!--            </v-col>-->
<!--            <v-col cols="12" md="4">-->
<!--              <v-text-field v-model="user.patronymic" label="Отчество" readonly/>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--          <v-row>-->
<!--            <v-col cols="12" md="4">-->
<!--              <v-text-field v-model="user.position" label="Должность" readonly />-->
<!--            </v-col>-->
<!--          </v-row>-->
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="user.email" label="E-Mail" readonly />
                <date-picker v-model="user.birthday" :disabled="true"></date-picker>
              </v-col>
              <v-col cols="12" md="4">
                <v-masked-input
                  v-for="i in mobile_count"
                  :key="i"
                  v-model="user.mobile[i-1]"
                  :label="`Мобильный #${i}`"
                  :rules="[v => !!v || 'Обязательное поле']"
                  :mask="'+7 (###) ####-###'"
                >
                  <template v-slot:prepend>{{ `${i}.` }}</template>
                  <template v-slot:append-outer>
                    <v-btn icon color="primary" @click="add_mobile">
                      <v-icon>mdi-plus-thick</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="remove_mobile(i-1)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </template>
                </v-masked-input>
                <v-text-field v-model="user.phone" label="Телефон" readonly />
                <v-combobox v-model="user.phone_inner" small-chips multiple label="Внутренний номер" />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="user.telegram" label="Telegram" prefix="@" />
                <v-file-input
                  v-model="avatar"
                  label="Аватар"
                  chips
                  dense
                />
              </v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn small color="primary" :disabled="!valid" @click="saveData">Сохранить</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </base-card>
      </v-col>
      <v-col cols="12" lg="4">
        <base-card
          color="indigo"
          icon="mdi-vuetify"
          inline
          class="px-5 py-3"
          title="Смена пароля"
        >
          <v-form
            ref="changePasswordForm"
            v-model="validatePassword"
            lazy-validation
          >
            <v-text-field
              v-model="password1"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              :rules="[rules.required, rules.min]"
              label="Пароль"
              @click:append="show1 = !show1"
            />
            <v-text-field
              v-model="password2"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              :rules="[rules.required, rules.min, passMatch]"
              label="Подтверждение пароля"
              @click:append="show2 = !show2"
            />
            <v-divider class="mb-3"></v-divider>
            <v-btn
              :disabled="!validatePassword"
              color="success"
              small
              outlined
              @click="changePassword"
            >Изменить пароль</v-btn>
          </v-form>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VMaskedInput from '../../components/VMaskedInput'
export default {
  name: 'Profile',
  components: { VMaskedInput },
  data: () => ({
    user: {
      active: true,
      avatar: null,
      department: [],
      email: null,
      mobile: [],
      name: null,
      patronymic: null,
      phone: null,
      phone_inner: [],
      position: null,
      surname: null,
      telegram: '',
      birthday: ''
    },
    valid: true,
    avatar: null,
    password1: '',
    password2: '',
    show1: false,
    show2: false,
    validatePassword: true,
    rules: {
      required: v => !!v || 'Обязательное поле',
      min: v => v.length >= 8 || 'Длина не менее 8 символов'
    }
  }),
  created () {
  },
  mounted () {
    this.getData()
  },
  computed: {
    passMatch: function () {
      return this.password2 === this.password1 || 'Пароли не совпадают'
    },
    avatarPath: function () {
      return (this.user.avatar === null) ? '/static/default_avatar.png' : `/api/v1/uploads/avatar/${this.user.avatar}`
    },
    title: function () {
      return `${this.$auth.user().name}<br/><span class="text-subtitle-1">${this.user.position}</span>`
    },
    mobile_count: function () {
      return (!this.user.mobile) ? 0 : this.user.mobile.length
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('user/GET_USER_OWN')
        .then(response => {
          this.user = response.user
          if (!this.user.mobile) {
            this.user.mobile = ['']
          }
        })
    },
    saveData () {
      if (!this.$refs.user_form.validate()) {
        return
      }
      const payload = {}
      Object.assign(payload, this.user)
      payload.phone_inner = []
      if (this.user.phone_inner !== null) {
        this.user.phone_inner.forEach(function (item, index, arr) {
          payload.phone_inner.push(parseInt(item))
        })
      }
      const rawData = JSON.stringify(payload)
      const formData = new FormData()
      formData.append('avatar', this.avatar)
      formData.append('data', rawData)
      this.$store.dispatch('user/PUT_OWN', formData)
        .then(response => {
          this.user = response.user
          this.avatar = null
          this.flashMessage.success({ title: 'Сохранение', message: 'Профиль успешно сохранены.' })
        })
    },
    changePassword () {
      const payload = {
        password1: this.password1,
        password2: this.password2
      }
      this.$store.commit('OVERLAY_ON')
      this.$store.dispatch('auth/POST_CHANGE_PASSWORD', payload)
        .then(response => {
          this.password1 = ''
          this.password2 = ''
          this.$refs.changePasswordForm.resetValidation()
          this.flashMessage.success({ title: 'Профиль', message: 'Пароль успешно изменен' })
        })
        .finally(() => { this.$store.commit('OVERLAY_OFF') })
    },
    uploadFile () {
      const id = this.$auth.user().id
      const formData = new FormData()
      formData.append('avatar', this.avatar)
      this.$store.dispatch('user/POST_UPLOAD_AVATAR', { id: id, formData: formData })
        .then(response => {
          this.flashMessage.success({ title: 'Сохранение', message: 'Аватар загружен.' })
          this.avatar = []
          this.user.avatar = response.avatar
        })
        .catch(error => {
          const message = error.response.data.message || ''
          this.flashMessage.error({ title: 'Сохранение', message: `Ошибка загрузки аватара. ${message}` })
        })
    },
    add_mobile () {
      this.user.mobile.push('')
    },
    remove_mobile (val) {
      if (this.mobile_count > 1) {
        this.user.mobile.splice(val, 1)
      }
    }
  }
}
</script>

<style scoped>

</style>
